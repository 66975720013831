import { h } from './element';
import { cssPrefix } from '../config';
import Logo from './logo';
import Link from './Link';
import Line from './Line';
import Qrcode from './qrcode';
const links1 = [
  {
    name: '正能量电子网',
    link: 'https://www.bom.ai/',
  },
  {
    name: '正能量ERP',
    link: 'http://erp.bom.ai',
  },
  {
    name: '云配',
    link: 'https://www.bombuy.com/',
  },
  {
    name: '供应链',
    link: 'https://www.bom.ai/supplychain',
  },
  {
    name: '商城系统',
    link: 'http://erp.bom.ai/erp-shop.html',
  },
  {
    name: '官网系统',
    link: 'http://vshop.bom.ai/',
  },
  {
    name: '开放平台',
    link: 'https://open.bom.ai',
  },
  {
    name: '芯扒客',
    link: 'http://news.bom.ai/',
  },
];
const links2 = [
  {
    name: '关于我们',
    link: 'https://www.bom.ai/AboutIndex',
  },
  {
    name: '付款信息',
    link: 'https://www.bom.ai/AboutIndex#FKX',
  },
  {
    name: '更多企业动态',
    link: 'http://about.bom.ai/info/notice/list.html',
  },
];
const links3 = [
  {
    name: '客服热线：',
    texts: ['400-699-2899(转2)'],
  },
  {
    name: '客服QQ：',
    texts: ['800106568'],
  },
  {
    name: '销售咨询：',
    texts: ['400-699-2899(转1)'],
  },
  {
    name: '销售QQ：',
    texts: ['800106568'],
  },
  {
    name: '官方公众号：',
    texts: ['正能量大数据'],
  },
];

class Ft {
  constructor(target) {
    this.el = h('div', `${cssPrefix}-ft`);
    target.child(this.el);

    new Logo(this.el);
    new Link(this.el, '旗下产品', links1);
    new Link(this.el, '相关信息', links2);
    new Link(this.el, '联系我们', links3);
    new Line(this.el);
    new Qrcode(
      this.el,
      // '扫一扫  云价格随身查',
      // '//static.bom.ai/assets/img/footer_wechat_qrcode.png'
      '扫码查云价格',
      '//static.bom.ai/assets/img/footer_wechat_qrcode.png'
    );
    new Qrcode(
      this.el,
      '咨询微信客服',
      '//static.bom.ai/assets/img/footer_qywechat_qrcode.png'
    );
  }
}

export default Ft;
