import { h } from './element';
import { cssPrefix } from '../config';
class Line {
  constructor(target) {
    this.el = h('div', `${cssPrefix}-lineBox`);
    const lineI = h('i', `${cssPrefix}-line`);
    this.el.child(lineI);
    target.child(this.el);
  }
}

export default Line;
