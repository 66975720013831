import { h } from './element';
import { cssPrefix } from '../config';
class Qrcode {
  constructor(target, title, url) {
    this.el = h('div', `${cssPrefix}-qrcodeBox`);

    const qrcodeImg = h('img', `${cssPrefix}-qrcode`);
    qrcodeImg.attr('src', url);

    const qrcideText = h('div', `${cssPrefix}-qrcodeText`);
    qrcideText.html(title);

    this.el.child(qrcodeImg);
    this.el.child(qrcideText);

    target.child(this.el);
  }
}

export default Qrcode;
