import { h } from './element';
import { cssPrefix } from '../config';
class Address {
  constructor(target) {
    this.el = h('div', `${cssPrefix}-address`);
    const line1 = h('span', `${cssPrefix}-addressLine`);
    const line2 = h('span', `${cssPrefix}-addressLine`);
    const line3 = h('span', `${cssPrefix}-addressLine`);
    const line4 = h('span', `${cssPrefix}-addressLine`);
    line1.html(
      '地址：〖深圳总部〗：深圳市福田区华强北街道振兴路桑达工业区418栋曼哈数码广场A栋四楼双创中心东面'
    );
    line2.html(
      '〖香港〗：九龍觀塘成業街19-21號，成業工業大廈3樓33室 ADDRESS: Unit 33, 3/F.,Shing Yip Industrial Building,No.19-21 Shing Yip Street,Kwun Tong,Kowloon'
    );
    line3.html('〖上海〗：上海市静安区天目西路218嘉里不夜城b座1207室');
    line4.html('〖四川〗：四川省内江市东兴区红桥街112号集中发展区4楼');
    this.el.child(line1);
    this.el.child(line2);
    this.el.child(line3);
    this.el.child(line4);
    target.child(this.el);
  }
}
class Fb {
  constructor(target) {
    this.el = h('div', `${cssPrefix}-fb`);

    const htmlString = `©2017-2020 深圳市正能量网络技术有限公司 版权所有 <br/>
		<a  href="https://beian.miit.gov.cn/" target="_blank">互联网ICP备案：粤ICP备17005480号</a><br />
		<a href="/notice/BusinessLicense" target="_blank">增值电信业务经营许可证 粤B2-20201131</a>`;

    const record = h('div', `${cssPrefix}-record`);
    const text = h('span', `${cssPrefix}-text`);
    text.html(htmlString);
    // text.html('©2017 深圳市正能量网络技术有限公司 - ');
    // const aLink = h('a', `${cssPrefix}-fba`);
    // aLink.attr('href', 'https://beian.miit.gov.cn/');
    // aLink.html('粤ICP备17005480号');

    // record.child(text);
    // record.child(aLink);

    record.child(text);

    this.el.child(record);
    new Address(this.el);
    target.child(this.el);
  }
}

export default Fb;
