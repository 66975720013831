import { h } from './element';
import { cssPrefix } from '../config';
class Link {
  constructor(target, title = '', links = []) {
    this.el = h('div', `${cssPrefix}-link`);
    target.child(this.el);

    const h3 = h('h3', `${cssPrefix}-link-header`);
    h3.html(title);

    this.el.child(h3);

    for (let i = 0; i < links.length; i++) {
      const linkA = h('a', `${cssPrefix}-link-a`);
      const label = h('span', `${cssPrefix}-link-label`);
      label.html(links[i].name);
      linkA.child(label);
      if (links[i].link) {
        linkA.attr('href', links[i].link);
        linkA.attr('target', '_blank');
      }
      if (links[i].texts) {
        const textBox = h('span', `${cssPrefix}-link-textBox`);
        linkA.child(textBox);
        for (let j = 0; j < links[i].texts.length; j++) {
          const text = h('span', `${cssPrefix}-link-text`);
          text.html(links[i].texts[j]);
          textBox.child(text);
        }
      }

      this.el.child(linkA);
    }
  }
}

export default Link;
