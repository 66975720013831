import { h } from './element';
import { cssPrefix } from '../config';
class Logo {
  constructor(target) {
    this.el = h('div', `${cssPrefix}-logobox`);
    target.child(this.el);

    const logo = h('img', `${cssPrefix}-logo`);
    logo.attr('src', '//static.bom.ai/assets/img/footer_logos.png');

    const logoText = h('div', `${cssPrefix}-logotext`);
    logoText.html(' —— 关注您所需，传播正能量！');

    this.el.child(logo);
    this.el.child(logoText);
  }
}

export default Logo;
