class Element {
  constructor(el, className) {
    this.el = el;
    if (typeof el === 'string') {
      this.el = document.createElement(el);
    }
    this.css(className);
  }

  css(className) {
    if (!this.el.classList.contains(className)) {
      this.el.classList.add(className);
    }
    // this.el.classList.toggle(className)
  }

  attr(name, value) {
    if (!value) {
      return this.el.getAttribute(name);
    }
    this.el.setAttribute(name, value);
  }

  child(el) {
    this.el.append(el.el);
  }

  html(content) {
    if (!content) {
      return this.el.innerHTML;
    }
    this.el.innerHTML = content;
  }
}

function h(el, className) {
  return new Element(el, className);
}

export { Element, h };
