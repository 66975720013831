import { cssPrefix } from './config';

export const styleTextContent = `
	h3 {
		margin-top: 0;
    font-size: 14px;
    color: #333;
    margin-bottom: 16px;
    font-weight: 700;
	}
	a {
		text-decoration: none;
		color: #666;
	}
	a:hover{
		text-decoration: underline;
	}
	.${cssPrefix} {
		background-color: #eee;
	}
	.${cssPrefix}-container {
    width: 1280px;
    margin: 0 auto;
	}
	.${cssPrefix}-ft {
		padding-top: 40px;
	}
	.${cssPrefix}-logobox {
    display: inline-block;
    margin-left: 40px;
    vertical-align: top;
	}
	.${cssPrefix}-logo {
    width: 234px;
		height: 68px;
		margin-right: 85px;
	}
	.${cssPrefix}-logotext {
		font-size: 16px;
    color: grey;
    margin-top: 20px;
	}

	.${cssPrefix}-link {
		padding-left: 38px;
		display: inline-block;
    vertical-align: top;
	}
	.${cssPrefix}-link-a {
		display: block;
    color: #4d4d4d;
    font-size: 14px;
    margin-bottom: 4px;
		line-height: 19px;
		display: flex;
	}
	.${cssPrefix}-link-textBox {
		display: flex;
    flex-direction: column;
	}
	.${cssPrefix}-link-text {
		padding-bottom: 5px;
	}

	.${cssPrefix}-lineBox {
		display: inline-block;
    vertical-align: top;
	}
	.${cssPrefix}-line {
		width: 1px;
    background-color: #ccc;
    height: 150px;
    margin-right: 60px;
		margin-left: 80px;
		display: inline-block;
	}

	.${cssPrefix}-qrcodeBox {
		display: inline-block;
	}
	.${cssPrefix}-qrcode {
		vertical-align: top;
    width: 138px;
    height: 138px;
	}
	.${cssPrefix}-qrcodeText {
		font-size: 14px;
    color: #333;
    text-align: center;
    font-weight: 700;
    margin-top: 8px;
	}

	.${cssPrefix}-fb {
		width: 100%;
    height: 130px;
    line-height: 59px;
    display: flex;
		align-items: center;
		justify-content: space-between;
    border-top: 1px solid #dbdbdb;
    text-align: center;
	}
	.${cssPrefix}-record {
		color: #666;
		width: 500px;
    font-size: 12px;
    display: inline-block;
		line-height: 19px;
		text-align: left;
		padding-left: 40px;
	}


	.${cssPrefix}-address {
		margin-left: 108px;
    text-align: left;
    margin-top: 11px;
    vertical-align: top;
		font-size: 12px;
		color: #666;
    line-height: 19px;
    display: inline-block;
	}
	.${cssPrefix}-addressLine {
		display: block;
		padding-left: 36px;
	}
	.${cssPrefix}-addressLine:first-child {
		padding-left: 0px;
	}
`;
