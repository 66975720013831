import { h } from './component/element';
import { cssPrefix, __DEV__ } from './config';
import Ft from './component/ft';
import Fb from './component/fb';
import { styleTextContent } from './style';

customElements.define(
  'znl-common-footer',
  class extends HTMLElement {
    constructor() {
      super();

      const rootEl = this.init();

      const style = document.createElement('style');
      style.textContent = styleTextContent;

      const shadowRoot = this.attachShadow({
        mode: __DEV__ ? 'open' : 'closed'
      });
      shadowRoot.appendChild(rootEl);
      shadowRoot.appendChild(style);
    }

    init() {
      const root = h('footer', cssPrefix);
      const container = h('footer', `${cssPrefix}-container`);
      root.child(container);
      new Ft(container);
      new Fb(container);
      return root.el;
    }
  }
);
